/*@import "primereact/resources/themes/tailwind-light/theme.css";*/
/*@import "primereact/resources/primereact.min.css";*/
/*@import "primeicons/primeicons.css";*/

@import 'reactflow/dist/style.css';
@import '@chatscope/chat-ui-kit-styles/dist/default/styles.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

body {
    background-color: #181729 !important;
    /*@apply w-fit h-fit*/
}

/*.ds-form-input {*/
/*    @apply block w-full*/
/*    rounded-md border-0*/
/*    py-1.5 text-gray-900*/
/*    shadow-sm ring-1 ring-inset ring-gray-300*/
/*    placeholder:text-gray-400 focus:ring-2*/
/*    focus:ring-inset focus:ring-indigo-600*/
/*    sm:text-sm sm:leading-6;*/
/*}*/

/*.btn {*/
/*    border: none;*/
/*    background-color: #233346;*/
/*    cursor: pointer;*/
/*    border-radius: 6px;*/
/*    color: white;*/
/*    font-weight: bold;*/
/*    padding: 12px 14px;*/
/*    font-size: 18px;*/
/*    margin-top: 8px;*/
/*}*/

/*.profile-card {*/
/*    text-align: center;*/
/*    align-items: center;*/
/*    display: flex;*/
/*    color: white;*/
/*    background-color: #181729;*/
/*    padding: 16px 28px;*/
/*    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/
/*    margin-bottom: 1px;*/

/*}*/

/*.project-type-card {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    border: 1px solid #252d37;*/
/*    background-color: #252d37;*/
/*    color: #fcfcfc;*/
/*    justify-content: center;*/
/*    padding: 1rem 0rem;*/
/*    width: 50%;*/

/*}*/

/*.units-node__select select {*/
/*    width: 100%;*/
/*    margin-top: 5px;*/
/*    font-size: 10px;*/
/*}*/


.custom-node__select {
    position: relative;
    /*margin-bottom: 10px;*/
}

.edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
}

.edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
}

.btn {
    @apply pointer-events-auto rounded-md bg-indigo-600 px-3 py-2 text-[0.8125rem] font-semibold leading-5 text-white hover:bg-indigo-500
}

label {
    @apply text-gray-500 text-sm font-medium dark:text-gray-100
}

input, .input-class {
    @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
    placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}

.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode {
    height: 20px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }

    .dndflow aside {
        width: 20%;
        max-width: 250px;
    }
}

.loginCard {
    font-family: "Overpass", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #181729;
}

.dropdown {
    width: 50%;
}

.tabledata {
    @apply text-left border p-4
}

.tableheader {
    @apply text-left border p-4
}

.table {
    @apply border border-collapse text-white
}

.tablecaption {
    @apply text-lg text-white

}

.tablecontainer {
    @apply mt-12 flex mx-12
}